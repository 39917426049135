import React from 'react'
import PropTypes from 'prop-types'

import { StyledInfo } from './styles'
import { Typo2 } from '../Typography/Typography'

const Info = ({ text }) => (
  <StyledInfo>
    <Typo2 fontSize="1.125rem">{text}</Typo2>
  </StyledInfo>
)

export default Info

Info.propTypes = {
  text: PropTypes.string.isRequired,
}

import React, { useState } from 'react'

import CincopaVideo from '../../../components/Video/CincopaVideo'
import Container from '../../../components/Container'
import { bgTheme } from '../../utils'
import VideoPlaceholder from '../../../components/VideoPlaceholder/VideoPlaceholder'

const FlexWVideo = ({ block, index }) => {
  const { bg, videoCincopaId, videoImagePlaceholder } = block

  const videoPlaceholder =
    videoImagePlaceholder.imageFile?.childImageSharp.fluid

  const [videoVisible, setVideoVisible] = useState(false)

  const videoBg = bgTheme(bg)

  const handleClickVideoVisible = () => setVideoVisible(true)
  return (
    <Container key={index} bg={videoBg}>
      <Container
        maxWidth={['', '', '80%']}
        ml="auto"
        mr="auto"
        mb={['-0.0625rem', '0']}
        className="uiAnimBottom"
      >
        {videoPlaceholder && !videoVisible ? (
          <VideoPlaceholder
            image={videoPlaceholder}
            handleClickVideoVisible={handleClickVideoVisible}
          />
        ) : (
          <CincopaVideo id={videoCincopaId} />
        )}
      </Container>
    </Container>
  )
}

export default FlexWVideo

import styled from 'styled-components'
import { rgba } from 'polished'

const StyledInfo = styled.div`
  border: 1px dashed ${({ theme }) => rgba(theme.colors.yellow, 0.5)};
  padding: 1rem;
  margin-top: 1.875rem;
  color: ${({ theme }) => theme.colors.yellow};
`

export { StyledInfo }

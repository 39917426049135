import React, { useMemo } from 'react'

import FlexBlockActions from './contentBlocks/FlexBlockActions'
import FlexPictureBox from './contentBlocks/FlexPictureBox'
import FlexCallout from './contentBlocks/FlexCallout'
import FlexWelcome from './contentBlocks/FlexWelcome'
import FlexModelsListSlider from './contentBlocks/FlexModelsListSlider'
import FlexOtherModelsSlider from './contentBlocks/FlexOtherModelsSlider'
import FlexGallerySlider from './contentBlocks/FlexGallerySlider'
import FlexVideo from './contentBlocks/FlexVideo'
import { getAcfType } from './utils'

/**
 * Function that gets content blocks and returns mapped components
 *
 * @param {Array} contentBlocks Array of blocks to map
 */
const FlexContentBlocks = ({ contentBlocks, pageData: { wp } }) => {
  const type = useMemo(() => getAcfType(wp), [wp])

  return contentBlocks.map((block, index) => {
    const { fieldGroupName } = block

    let blockActions
    const blockHasActions = block.hasOwnProperty('actions')

    if (blockHasActions) {
      const {
        actions: { showActions, actions },
      } = block

      blockActions = showActions ? (
        <FlexBlockActions pageType={type} actions={actions} />
      ) : (
        false
      )
    }

    switch (fieldGroupName) {
      case `${type}_ContentBlocks_Content_Welcome`:
        return <FlexWelcome key={index} {...{ block, index }} />

      case `${type}_ContentBlocks_Content_PictureAndContentBox`:
        return (
          <FlexPictureBox
            key={index}
            {...{ blockActions, blockHasActions, block, index }}
          />
        )
      case `${type}_ContentBlocks_Content_Callout`:
        return (
          <FlexCallout
            key={index}
            {...{ blockActions, blockHasActions, block, index }}
          />
        )
      case `${type}_ContentBlocks_Content_ModelsSlider`:
        return <FlexModelsListSlider key={index} {...{ block, index, wp }} />
      case `${type}_ContentBlocks_Content_Video`:
        return <FlexVideo key={index} block={block} index={index} />

      case `${type}_ContentBlocks_Content_OtherModelsSlider`:
        return (
          <FlexOtherModelsSlider
            key={index}
            block={block}
            index={index}
            wp={wp}
          />
        )
      case `${type}_ContentBlocks_Content_GallerySlider`:
        const hasImages =
          block.hasOwnProperty('images') && block.images !== null
        if (hasImages) {
          return (
            <FlexGallerySlider
              key={index}
              block={block}
              index={index}
              wp={wp}
            />
          )
        }
        break
      default:
        break
    }
    return false
  })
}

export default FlexContentBlocks

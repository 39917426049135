import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  StyledCallout,
  StyledCalloutWrapper,
  StyledCalloutTitle,
  StyledCalloutContent,
  StyledCalloutAction,
  StyledCalloutImg,
} from './styles'
import { fluidPropType } from '../../constants/proptypes'
import Inner from '../Inner'
import Image from '../Image/Image'

const Callout = ({ title, children, actions, image, centeredOnMobile }) => (
  <StyledCallout centeredOnMobile={centeredOnMobile}>
    <Inner>
      {image && (
        <StyledCalloutImg>
          <Image fluid={image.fluid} />
        </StyledCalloutImg>
      )}
      <StyledCalloutWrapper className="uiAnimBottom">
        {title && <StyledCalloutTitle>{title}</StyledCalloutTitle>}
        {children && <StyledCalloutContent>{children}</StyledCalloutContent>}
        {actions && <StyledCalloutAction>{actions}</StyledCalloutAction>}
      </StyledCalloutWrapper>
    </Inner>
  </StyledCallout>
)

export default Callout

Callout.defaultProps = {
  children: '',
  actions: '',
  centeredOnMobile: false,
}

Callout.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  actions: PropTypes.node,
  image: fluidPropType,
  centeredOnMobile: PropTypes.bool,
}

export const query = graphql`
  fragment city_calloutFields on wp_City_Acf_ContentBlocks_Content_Callout {
    fieldGroupName
    bg
    pbPt
    title
    content
    blockStyle
    hasAdditionalSpacingOnBottom
    image {
      mediaItemUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    actions {
      showActions
      actions {
        internalOrExternal
        external {
          target
          title
          url
        }
        internal {
          title
          target {
            ... on wp_Post {
              contentType {
                node {
                  name
                }
              }
              slug
            }
            ... on wp_Page {
              contentType {
                node {
                  name
                }
              }
              slug
            }
            ... on wp_Model {
              contentType {
                node {
                  name
                }
              }
              slug
            }
          }
        }
      }
    }
  }
`

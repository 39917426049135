export const getAcfType = (wp) => {
  let type

  if (wp.hasOwnProperty('city')) {
    type = 'City_Acf'
  }

  if (wp.hasOwnProperty('category')) {
    type = 'category_Acf'
  }

  if (wp.hasOwnProperty('page')) {
    type = 'page_Acf'
  }

  if (wp.page?.hasOwnProperty('servicesAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Servicesacf'
    }
  }

  if (wp.page?.hasOwnProperty('gentlemensguideAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Gentlemensguideacf'
    }
  }

  if (wp.page?.hasOwnProperty('pricingAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Pricingacf'
    }
  }

  if (wp.page?.hasOwnProperty('aboutAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Aboutacf'
    }
  }

  if (wp.page?.hasOwnProperty('bookingAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Bookingacf'
    }
  }

  if (wp.page?.hasOwnProperty('viploginAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Viploginacf'
    }
  }
  if (wp.page?.hasOwnProperty('vipWelcomeAcf')) {
    if (wp.page?.hasOwnProperty('acf')) {
      type = 'page_Acf'
    } else {
      type = 'page_Vipwelcomeacf'
    }
  }

  return type
}

import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  StyledPopupModal,
  StyledPopupModalInner,
  StyledPopupModalAction,
  StyledPopupModalClose,
} from './styles'
import CloseIcon from '../Icons/CloseIcon'

StyledPopupModal.setAppElement('body')

const PopupModal = ({ closeModal, isOpen, children }) => (
  <StyledPopupModal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={{
      overlay: { background: 'rgba(0,0,0,0.8)' },
    }}
  >
    <StyledPopupModalAction>
      <StyledPopupModalClose type="button" onClick={closeModal}>
        <CloseIcon />
      </StyledPopupModalClose>
    </StyledPopupModalAction>
    <StyledPopupModalInner>{children}</StyledPopupModalInner>
  </StyledPopupModal>
)

PopupModal.defaultProps = {
  closeModal: _noop,
  children: '',
  isOpen: false,
}

PopupModal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
}

export default PopupModal

import React, { useContext } from 'react'

import Callout from '../../../components/Callout/Callout'
import Container from '../../../components/Container'
import {
  Body,
  Heading5,
  Typo2,
} from '../../../components/Typography/Typography'
import NewsListContext from '../../../contexts/NewsListContext'
import { bgTheme, changeLinksPath } from '../../utils'

const FlexCallout = ({ block, index, blockActions, blockHasActions }) => {
  const {
    bg,
    title,
    content,
    blockStyle,
    hasAdditionalSpacingOnBottom,
    pbPt,
    image,
  } = block

  const { newsList } = useContext(NewsListContext)

  const isCenteredOnMobile = blockStyle === 'alt'
  const isAlt = blockStyle === 'alt2'
  const additionalSpaceBottom = hasAdditionalSpacingOnBottom
  let paddingsTop
  let paddingsBottom

  switch (pbPt) {
    case 'large':
      paddingsTop = ['3.125rem', '6.75rem']
      paddingsBottom = [
        '3.125rem',
        '',
        '',
        additionalSpaceBottom ? '8.25rem' : '6.75rem',
      ]
      break
    case 'small_shorter_top':
      paddingsTop = '1.375rem'
      paddingsBottom = [
        '2.875rem',
        '',
        '',
        additionalSpaceBottom ? ' 8.25rem' : '5.25rem',
      ]
      break
    case 'small_shorter_bottom':
      paddingsTop = ['2.875rem', '5.25rem']
      paddingsBottom = '1.375rem'
      break
    default:
      paddingsTop = ['2.875rem', '5.25rem']
      paddingsBottom = [
        '2.875rem',
        '',
        '',
        additionalSpaceBottom ? ' 8.25rem' : '5.25rem',
      ]
      break
  }

  const bgImage = image ? image.imageFile.childImageSharp : null
  const calloutBg = bgTheme(bg)
  const calloutColor = bg
  const Content = isAlt ? Typo2 : Body

  return (
    <Container
      key={index}
      bg={calloutBg}
      pt={isAlt ? ['2rem', '2.5rem', '10.875rem'] : paddingsTop}
      pb={isAlt ? ['3.125rem', '5rem', '12.375rem'] : paddingsBottom}
      mb={isAlt ? ['', '', '3.125rem'] : ''}
      color={
        calloutColor === 'lightGrey' || calloutColor === 'white'
          ? 'black'
          : 'white'
      }
      position="relative"
      overflow="hidden"
    >
      <Callout
        title={
          title ? (
            <Heading5
              as="h2"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          ) : null
        }
        actions={blockHasActions ? blockActions : null}
        image={bgImage}
        centeredOnMobile={isCenteredOnMobile || isAlt}
      >
        {!!content && (
          <Container maxWidth={isAlt ? '56.875rem' : '100%'} ml="auto" mr="auto">
            <Content
              as="h3"
              dangerouslySetInnerHTML={{
                __html: changeLinksPath(content, newsList),
              }}
            />
          </Container>
        )}
      </Callout>
    </Container>
  )
}

export default FlexCallout

import styled, { css } from 'styled-components'

import {
  StyledShadowedArrowPrev,
  StyledShadowedArrowNext,
} from '../SliderNavigation/ShadowedArrows/styles'
import media, { customMedia } from '../../../common/MediaQueries'
import { StyledModelItem } from '../../ModelItem/styles'

const StyledModelsListSliderInner = styled.div`
  max-width: ${({ theme }) => theme.sizes.inner};

  @media ${media.phone} {
    width: 90%;
    margin: 0 auto;
  }
`

const StyledModelsListSlider = styled.div`
  .slick-slider {
    position: static;
  }

  .slick-list {
    overflow: visible;
  }

  ${StyledModelItem} {
    height: 100%;
  }

  @media ${customMedia.maxPhone} {
    max-width: 90%;
    margin: 0 auto;
  }
`

const StyledModelsListSliderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 -0.3125rem;

  .slick-slide {
    margin: 0 0.3125rem;
  }

  ${StyledShadowedArrowPrev},
  ${StyledShadowedArrowNext} {
    display: none;
  }

  ${StyledShadowedArrowPrev} {
    left: 1.5rem;
  }

  ${StyledShadowedArrowNext} {
    right: 1.5rem;
  }

  ${({ alt }) =>
    alt &&
    css`
      position: relative;

      ${StyledShadowedArrowPrev},
      ${StyledShadowedArrowNext} {
        display: flex;
        color: ${({ theme }) => theme.colors.yellow};
      }

      ${StyledShadowedArrowPrev} {
        transform: translateY(-2.375rem) translate(-50%, -50%) rotate(-180deg);

        &:hover {
          transform: translateY(-2.375rem) translate(-50%, -50%) rotate(-180deg)
            scale3d(1.05, 1.05, 1.05);
        }
      }

      ${StyledShadowedArrowNext} {
        transform: translateY(-2.375rem) translate(50%, -50%);

        &:hover {
          transform: translateY(-2.375rem) translate(50%, -50%)
            scale3d(1.05, 1.05, 1.05);
        }
      }
    `}


  @media ${media.phone} {       
    margin: 0 -0.5rem;

    .slick-slide {
      margin: 0 0.5rem;
    }

    ${StyledShadowedArrowPrev},
    ${StyledShadowedArrowNext} {
      display: flex;
    }

    ${({ alt }) =>
      !alt &&
      css`
        ${StyledShadowedArrowPrev},
        ${StyledShadowedArrowNext} {
          color: ${({ theme }) => theme.colors.white};
        }
      `}
   
    ${({ alt }) =>
      alt &&
      css`
        ${StyledModelsListSliderInner} {
          width: 100%;
        }
      `}
  }

  @media ${media.tablet} {
    ${StyledShadowedArrowPrev} {
      left: 2.5rem;
    }

    ${StyledShadowedArrowNext} {
      right: 2.5rem;
    }
  }


  @media ${customMedia.inner} {
    ${({ alt }) =>
      alt &&
      css`
        ${StyledShadowedArrowPrev} {
          left: -1.5rem;
        }

        ${StyledShadowedArrowNext} {
          right: -1.5rem;
        }
      `}
  }

  @media ${media.desktop} {
    ${({ alt }) =>
      alt &&
      css`
        ${StyledShadowedArrowPrev} {
          left: 2.5rem;
        }

        ${StyledShadowedArrowNext} {
          right: 2.5rem;
        }
      `}
  }
`

export {
  StyledModelsListSliderWrapper,
  StyledModelsListSlider,
  StyledModelsListSliderInner,
}

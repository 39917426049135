import React, { useContext, useMemo } from 'react'

import { changeLinksPath } from '../../common/utils'
import NewsListContext from '../../contexts/NewsListContext'
import { Body } from '../Typography/Typography'

const HTMLBody = ({ html, ...rest }) => {
  const { newsList } = useContext(NewsListContext)
  const innerHtml = useMemo(
    () => ({
      __html: changeLinksPath(html, newsList),
    }),
    [html, newsList],
  )

  return <Body as="div" dangerouslySetInnerHTML={innerHtml} {...rest} />
}

export default HTMLBody

import React, { useState } from 'react'

import CincopaVideo from '../../../components/Video/CincopaVideo'
import Container from '../../../components/Container'
import Inner from '../../../components/Inner'
import FlexBlockActions from './FlexBlockActions'
import { Typo1, Heading9 } from '../../../components/Typography/Typography'
import { bgTheme } from '../../utils'
import VideoPlaceholder from '../../../components/VideoPlaceholder/VideoPlaceholder'

const FlexWelcome = ({ block, index }) => {
  const { bg, videoCincopaId, videoImagePlaceholder } = block

  const videoPlaceholder =
    videoImagePlaceholder?.imageFile?.childImageSharp.fluid

  const [videoVisible, setVideoVisible] = useState(false)

  let blockAction
  const blockHasAction = block.hasOwnProperty('action')

  if (blockHasAction) {
    const {
      action: { showAction, action },
    } = block

    blockAction = showAction ? <FlexBlockActions actions={action} /> : false
  }

  const welcomeBg = bgTheme(bg)
  const welcomeColor = bg

  const handleClickVideoVisible = () => setVideoVisible(true)

  return (
    <Container key={index} overflow="hidden">
      <Container
        pt={['2.5rem', '3.5rem', '6.25rem']}
        pb={['2.5rem', '3.5rem', '4rem']}
        bg={welcomeBg}
        color={
          welcomeColor === 'lightGrey' || welcomeColor === 'white'
            ? 'black'
            : 'white'
        }
      >
        <div className="uiAnimBottom">
          <Container textAlign="center" maxWidth="42.5rem" ml="auto" mr="auto">
            <Inner>
              <Heading9
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: block.title,
                }}
              />
            </Inner>
          </Container>

          <Container
            maxWidth={['', '', '80%']}
            ml="auto"
            mr="auto"
            mt="2.5rem"
            mb="2.5rem"
          >
            {videoPlaceholder && !videoVisible ? (
              <VideoPlaceholder
                image={videoPlaceholder}
                handleClickVideoVisible={handleClickVideoVisible}
              />
            ) : (
              <CincopaVideo id={videoCincopaId} />
            )}
          </Container>

          <Container textAlign="center" maxWidth="57rem" ml="auto" mr="auto">
            <Inner>
              <Typo1
                as="div"
                dangerouslySetInnerHTML={{
                  __html: block.content,
                }}
              />

              {blockHasAction && (
                <Container mt="2.5rem">{blockAction}</Container>
              )}
            </Inner>
          </Container>
        </div>
      </Container>
    </Container>
  )
}

export default FlexWelcome

import styled, { css } from 'styled-components'
import Link from 'gatsby-link'

import { decorReverseLink, transition } from '../../common/mixins'
import media from '../../common/MediaQueries'
import { Typo1, Typo5 } from '../Typography/Typography'
import { StyledImage } from '../Image/styles'

const StyledModelComingSoon = styled(Typo1)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 1;
  ${transition({ property: 'opacity', duration: '300ms' })}

  @media ${media.phone} {
    transform: translate(-50%);
  }
`

const StyledModelImgVip = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;

  ${({ isInvisible }) =>
    isInvisible &&
    css`
      visibility: hidden;
    `}
`

const StyledModelItemContent = styled.div`
  position: absolute;
  top: 100%;
  left: 1rem;
  width: calc(100% - 2rem);
  transition: transform 0.35s, top 0.35s;
  transform: translate3d(0, calc(-100% - 1rem), 0) translateZ(0);
  backface-visibility: hidden;

  @media ${media.tablet} {
    left: 1.25rem;
    width: calc(100% - 2 * 1.25rem);
    transform: translate3d(0, calc(-100% - 1.25rem), 0) translateZ(0);
  }
`

const StyledModelItemGradient = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    transparent 50%,
    ${({ theme }) => theme.colors.black}
  );

  @media ${media.tablet} {
    background: linear-gradient(
      180deg,
      transparent 70%,
      ${({ theme }) => theme.colors.black}
    );
  }
`

const StyledModelItemDetails = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    max-width: calc(100% - 5.5rem);
  }

  @media ${media.desktop} {
    top: 4.25rem;
  }
`

const StyledModelImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 67%;
  ${transition({ property: 'opacity', duration: '420ms' })}

  ${StyledImage} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ comingSoon }) =>
    comingSoon &&
    css`
      opacity: 0.4;
    `}
`

const StyledModelItemAction = styled(Typo5)`
  display: none;

  @media ${media.phone} {
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.yellow};
    ${decorReverseLink}
  }

  @media ${media.tablet} {
    right: 1.25rem;
    bottom: 1.25rem;
    opacity: 0;
    ${transition({ property: 'opacity', duration: '350ms' })}
  }
`

const StyledModelItemBio = styled.div`
  text-align: left;

  p + p {
    margin-top: 0.25rem;
  }

  @media ${media.phone} {
    max-width: calc(100% - 6rem);
  }
`

const StyledModelItem = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: currentColor;
  flex-grow: 1;

  ${({ alt }) =>
    alt &&
    css`
      ${StyledModelItemDetails} {
        top: 1rem;
      }
      ${StyledModelItemAction} {
        display: none;
      }
    `}

  @media ${media.tablet} {
    ${({ alt }) =>
      alt &&
      css`
        ${StyledModelItemAction} {
          display: block;
        }
      `}

    &:hover {
      ${StyledModelImgWrapper} {
        opacity: 0.3;

        ${({ comingSoon }) =>
          comingSoon &&
          css`
            opacity: 0.3;
          `}
      }
      ${StyledModelItemContent} {
        top: 0;
        transform: translate3d(0, 1.875rem, 0);
      }
      ${StyledModelItemAction} {
        opacity: 1;
      }
      ${StyledModelComingSoon} {
        opacity: 0;
      }
    }
  }
`

const StyledModelItemInner = styled.div`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
`

const StyledModelItemContentAlt = styled.div`
  position: relative;
  padding: 1.25rem 1rem 0 1rem;
  min-height: 4.75rem;

  @media ${media.phone} {
    padding: 1.25rem 0 0;
  }
`

const StyledModelItemActionAlt = styled(Typo5)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.yellow};
  ${decorReverseLink}


  @media ${media.phone} {
    right: 0;
  }

  @media ${media.tablet} {
    display: none;
  }
`

export {
  StyledModelItem,
  StyledModelItemInner,
  StyledModelImgWrapper,
  StyledModelItemGradient,
  StyledModelItemContent,
  StyledModelItemBio,
  StyledModelImgVip,
  StyledModelItemDetails,
  StyledModelItemAction,
  StyledModelItemActionAlt,
  StyledModelItemContentAlt,
  StyledModelComingSoon,
}

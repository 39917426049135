import { URL_PATHS } from '../../constants/urlPaths'
import { generateModelDetails } from '../utils'

const mapModelsToList = (models) =>
  models.map(({ node: { acf, featuredImage, id, title, slug } }) => {
    const { subtitle, imageToShowOnListing, comingSoon } = acf

    const image = imageToShowOnListing
      ? imageToShowOnListing?.imageFile.childImageSharp
      : featuredImage?.imageFile?.childImageSharp

    return {
      id,
      name: title,
      to: `${URL_PATHS.MODELS}/${slug}`,
      description: subtitle || '',
      image: image || {},
      details: generateModelDetails(acf),
      comingSoon,
    }
  })

export default mapModelsToList

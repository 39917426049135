import React from 'react'

import { Heading5 } from '../../../components/Typography/Typography'
import PictureBox from '../../../components/PictureBox/PictureBox'
import Container from '../../../components/Container'
import Info from '../../../components/Info/Info'
import { bgTheme } from '../../utils'
import HTMLBody from '../../../components/HTMLBody'

const FlexPictureBox = ({ block, index, blockHasActions, blockActions }) => {
  const {
    imageOrVideo,
    image,
    addSpacings,
    title,
    pictureBoxType,
    cincopaVideoId,
    contentColTheme,
    content,
    withInner,
    negativeMargin,
    quote,
    invertOnMobile,
  } = block

  const aboutPage = block.fieldGroupName.includes('Aboutacf')

  const isVideo = imageOrVideo === 'video' || null
  const newImage = isVideo
    ? image !== null
      ? image.imageFile?.childImageSharp
      : null
    : image.imageFile?.childImageSharp
  const spacingBottom = addSpacings ? ['', '3.875rem'] : ''

  const pictureBoxBg = bgTheme(contentColTheme)
  const pictureBoxColor = contentColTheme

  const negativeMarginBig = negativeMargin === '-10rem' ? '10rem' : null
  const negativeMarginMedium =
    negativeMargin === '-5.375rem' ? '-5.375rem' : null

  return (
    <Container
      pt={negativeMarginBig !== null && ['', '', '', negativeMarginBig]}
      zIndex={negativeMarginBig && '1'}
      position={negativeMarginBig && 'relative'}
      bg={negativeMarginMedium !== null && pictureBoxBg}
    >
      <Container
        pb={spacingBottom}
        bg={negativeMargin === null && pictureBoxBg}
        minHeight={aboutPage && index === 0 ? ['', '', '', '', '45rem'] : ''}
      >
        <PictureBox
          key={index}
          withInner={!withInner}
          pictureBoxType={pictureBoxType}
          bg={pictureBoxBg}
          color={
            pictureBoxColor === 'lightGrey' || pictureBoxColor === 'white'
              ? 'black'
              : 'white'
          }
          withVideo={isVideo}
          cincopaVideoId={cincopaVideoId}
          image={newImage}
          title={
            <Container maxWidth={aboutPage ? '29rem' : ''}>
              <Heading5
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Container>
          }
          negativeMargin={negativeMargin}
          contentFirstOnMobile={invertOnMobile}
        >
          <Container maxWidth={aboutPage ? '29rem' : ''}>
            <HTMLBody html={content} />
          </Container>
          {quote && <Info text={quote} />}
          {blockHasActions && blockActions}
        </PictureBox>
      </Container>
    </Container>
  )
}

export default FlexPictureBox

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import {
  StyledPictureBox,
  StyledPictureBoxInner,
  StyledPictureBoxHeader,
  StyledPictureBoxDesc,
  StyledPictureBoxContent,
  StyledPictureBoxMedia,
  StyledPictureBoxColMedia,
  StyledPictureBoxColContent,
  StyledPictureBoxPlay,
} from './styles'
import { PICTURE_BOX_TYPES } from '../../constants/pictureBoxTypes'
import { BG_TYPES } from '../../constants/bgTypes'
import Image from '../Image/Image'
import CincopaVideo from '../Video/CincopaVideo'
import { fluidPropType } from '../../constants/proptypes'
import PopupModal from '../PopupModal/PopupModal'

const PictureBox = ({
  pictureBoxType,
  bg,
  color,
  image,
  title,
  children,
  withInner,
  negativeMargin,
  withVideo,
  cincopaVideoId,
  contentFirstOnMobile,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isOpened, setIsOpened] = useState()

  const play = useStaticQuery(
    graphql`
      query {
        picPlay: file(relativePath: { eq: "play.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        picPlayHover: file(relativePath: { eq: "play-hover.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const closeModal = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const openModal = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  return (
    <>
      <StyledPictureBox
        color={color}
        pictureBoxType={pictureBoxType}
        withInner={withInner}
        contentFirstOnMobile={contentFirstOnMobile}
      >
        <StyledPictureBoxInner>
          <StyledPictureBoxColMedia>
            <StyledPictureBoxMedia
              negativeMargin={negativeMargin}
              onMouseEnter={() => setIsVisible(true)}
              onMouseLeave={() => setIsVisible(false)}
            >
              <Image fluid={image?.fluid} />
              {withVideo && (
                <StyledPictureBoxPlay onClick={openModal}>
                  <Img
                    fadeIn={false}
                    loading="eager"
                    fluid={
                      isVisible
                        ? play.picPlayHover.childImageSharp.fluid
                        : play.picPlay.childImageSharp.fluid
                    }
                  />
                </StyledPictureBoxPlay>
              )}
            </StyledPictureBoxMedia>
          </StyledPictureBoxColMedia>
          <StyledPictureBoxColContent bg={bg}>
            <StyledPictureBoxContent className="uiAnimBottom">
              <StyledPictureBoxHeader>{title}</StyledPictureBoxHeader>
              <StyledPictureBoxDesc>{children}</StyledPictureBoxDesc>
            </StyledPictureBoxContent>
          </StyledPictureBoxColContent>
        </StyledPictureBoxInner>
      </StyledPictureBox>
      {withVideo && (
        <PopupModal
          closeTimeoutMS={370}
          isOpen={isOpened}
          closeModal={closeModal}
        >
          <CincopaVideo id={cincopaVideoId} ratio={36.5} />
        </PopupModal>
      )}
    </>
  )
}

export default PictureBox

PictureBox.defaultProps = {
  pictureBoxType: PICTURE_BOX_TYPES.left,
  bg: BG_TYPES.black,
  color: 'white',
  withInner: false,
  withVideo: false,
  contentFirstOnMobile: false,
}

PictureBox.propTypes = {
  pictureBoxType: PropTypes.oneOf(Object.keys(PICTURE_BOX_TYPES)),
  bg: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  withInner: PropTypes.bool,
  negativeMargin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  image: fluidPropType,
  withVideo: PropTypes.bool,
  cincopaVideoId: PropTypes.string,
  contentFirstOnMobile: PropTypes.bool,
}

export const query = graphql`
  fragment city_pictureBoxFragment on wp_City_Acf_ContentBlocks_Content_PictureAndContentBox {
    fieldGroupName
    contentColTheme
    imageOrVideo
    cincopaVideoId
    image {
      mediaItemUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    negativeMargin
    pictureBoxType
    withInner
    title
    addSpacings
    content
    actions {
      showActions
      actions {
        internalOrExternal
        external {
          target
          title
          url
        }
        internal {
          title
          target {
            ... on wp_Post {
              contentType {
                node {
                  name
                }
              }
              slug
            }
            ... on wp_Page {
              contentType {
                node {
                  name
                }
              }
              slug
            }
            ... on wp_Model {
              contentType {
                node {
                  name
                }
              }
              slug
            }
          }
        }
      }
    }
  }
  fragment services_pictureBoxFragment on wp_Page_Servicesacf_ContentBlocks_Content_PictureAndContentBox {
    fieldGroupName
    contentColTheme
    imageOrVideo
    cincopaVideoId
    image {
      mediaItemUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    negativeMargin
    pictureBoxType
    withInner
    title
    addSpacings
    content
    actions {
      showActions
      actions {
        internalOrExternal
        external {
          target
          title
          url
        }
        internal {
          title
          target {
            ... on wp_Post {
              contentType {
                node {
                  name
                }
              }
              slug
            }
            ... on wp_Page {
              contentType {
                node {
                  name
                }
              }
              slug
            }
            ... on wp_Model {
              contentType {
                node {
                  name
                }
              }
              slug
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import { useInView } from 'react-intersection-observer'

import Container from '../../../components/Container'
import Inner from '../../../components/Inner'
import ModelsListSlider from '../../../components/Sliders/ModelsListSlider/ModelsListSlider'
import FlexBlockActions from './FlexBlockActions'
import { Heading7 } from '../../../components/Typography/Typography'
import { URL_PATHS } from '../../../constants/urlPaths'
import InternalLink from '../../../components/Buttons/InternalLink/InternalLink'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { bgTheme } from '../../utils'
import theme from '../../../themes/theme'
import mapModelsToList from '../mapModelsToList'
import HTMLBody from '../../../components/HTMLBody'

const FlexModelsListSlider = ({ index, wp, block }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const models = wp.models.edges

  const { title, subtitle, bg, isAlt } = block

  let blockAction
  const blockHasAction = block.hasOwnProperty('action')
  const modelsListSliderBg = bgTheme(bg)
  const modelsListSliderColor = bg

  if (blockHasAction) {
    const {
      action: { showAction, action },
    } = block

    const buttonType =
      modelsListSliderColor === 'lightBlack' ||
      modelsListSliderColor === 'black'
        ? BUTTON_TYPES.inverted
        : BUTTON_TYPES.primary

    blockAction = showAction ? (
      <FlexBlockActions actions={{ ...action, btnType: buttonType }} />
    ) : (
      false
    )
  }

  const modelsList = mapModelsToList(models)

  return (
    <Container
      inView={inView}
      bg={modelsListSliderBg}
      color={
        modelsListSliderColor === 'lightGrey' ||
        modelsListSliderColor === 'white'
          ? 'black'
          : 'white'
      }
      key={index}
      overflow="hidden"
      mt={isAlt ? '' : ['', '', '', '-5rem']}
      pt={isAlt ? ['2.5rem', '3.5rem', '6.25rem'] : ['1rem', '']}
      pb={
        isAlt ? ['2.5rem', '3.5rem', '6.25rem'] : ['1rem', '3.5rem', '4.375rem']
      }
    >
      <Inner
        pl={isAlt ? '0' : ['0', '1.875rem']}
        pr={isAlt ? '0' : ['0', '1.875rem']}
        maxWidth={isAlt ? '100%' : `${theme.sizes.inner}`}
      >
        <div className="uiAnimBottom">
          <>
            {title && (
              <Container textAlign="center">
                <Inner>
                  <Container mb="1.25rem">
                    <Heading7
                      as="h2"
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  </Container>
                </Inner>
              </Container>
            )}
            {subtitle && (
              <Container textAlign="center">
                <Inner>
                  <Container
                    maxWidth="45.5rem"
                    ml="auto"
                    mr="auto"
                    mb={['1.875rem', '2.5rem']}
                  >
                    <HTMLBody html={subtitle} />
                  </Container>
                </Inner>
              </Container>
            )}
            <Container
              mb={isAlt ? ['1.875rem', '2.5rem'] : ['1.875rem', '3.375rem']}
            >
              <div ref={ref}>
                {inView ? (
                  <ModelsListSlider alt={!isAlt} models={modelsList} />
                ) : null}
              </div>
            </Container>
          </>
        </div>

        <Container textAlign="center">
          {blockHasAction ? (
            blockAction
          ) : (
            <InternalLink
              to={URL_PATHS.MODELS}
              btnType={BUTTON_TYPES.secondary}
            >
              View All Models
            </InternalLink>
          )}
        </Container>
      </Inner>
    </Container>
  )
}

export default FlexModelsListSlider

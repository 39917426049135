import styled, { css } from 'styled-components'

import { PICTURE_BOX_TYPES } from '../../constants/pictureBoxTypes'
import media, { customMedia } from '../../common/MediaQueries'
import { decorLink, decorHoverLink } from '../../common/mixins'
import Container from '../Container'

const StyledPictureBoxCol = styled(Container)`
  @media ${media.phone} {
    display: flex;
    align-items: center;
    width: 50%;
  }
`

const StyledPictureBoxDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  > div {
    width: 100%;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: currentColor;
  }

  p + p {
    margin-top: 1.5rem;
  }

  a[btntype] {
    margin-top: 2.5rem;
    align-self: center;
  }

  a:not([class]) {
    ${decorLink};
    color: ${({ theme }) => theme.colors.yellow};

    &:hover {
      ${decorHoverLink};
    }
  }

  @media ${media.phone} {
    a[btntype] {
      align-self: auto;
    }
  }
`

const StyledPictureBoxColMedia = styled(StyledPictureBoxCol)`
  background: inherit;
`

const StyledPictureBoxColContent = styled(StyledPictureBoxCol)`
  padding: 0 1.875rem;

  @media ${media.phone} {
    padding: 0;
  }
`

const StyledPictureBoxMedia = styled.div`
  z-index: 1;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  position: relative;

  @media ${media.phone} {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    margin: 0;
  }

  @media ${media.tablet} {
    top: ${({ negativeMargin }) => negativeMargin};
  }
`

const StyledPictureBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${media.phone} {
    min-height: 35vw;
    flex-direction: row;
  }
`

const StyledPictureBoxContent = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media ${media.phone} {
    padding: 5.5rem 1.875rem;
    max-width: 30rem;
  }

  @media ${media.tabletSmall} {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  @media ${customMedia.inner} {
    padding: 6.75rem 0;
  }
`

const StyledPictureBox = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  min-height: inherit;


  @media ${customMedia.maxPhone} {
    ${({ contentFirstOnMobile }) =>
      contentFirstOnMobile &&
      css`
        ${StyledPictureBoxColContent} {
          order: 1;
        }
        ${StyledPictureBoxColMedia} {
          order: 2;
        }
      `}
  }

  @media ${media.phone} {
    overflow: visible;
    ${({ pictureBoxType }) =>
      pictureBoxType === PICTURE_BOX_TYPES.left &&
      css`
        ${StyledPictureBoxCol} {
          justify-content: flex-end;
        }

        ${StyledPictureBoxColContent} {
          position: relative;

          &::before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            width: 11.25rem;
            height: 100%;
            background: inherit;
          }
        }

        ${StyledPictureBoxMedia} {
          left: 0;
        }
      `}

    ${({ pictureBoxType }) =>
      pictureBoxType === PICTURE_BOX_TYPES.right &&
      css`
        ${StyledPictureBoxInner} {
          flex-direction: row-reverse;
        }

        ${StyledPictureBoxColContent} {
          position: relative;

          &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            bottom: 0;
            width: 11.25rem;
            height: 100%;
            background: inherit;
          }
        }

        ${StyledPictureBoxMedia} {
          right: 0;
        }
      `}

    ${({ withInner }) =>
      withInner &&
      css`
        ${StyledPictureBoxInner} {
          max-width: ${({ theme }) => theme.sizes.inner};
          margin: 0 auto;
          padding-left: 1.875rem;
          padding-right: 1.875rem;
        }
        ${StyledPictureBoxCol} {
          position: relative;
          align-items: flex-start;
          justify-content: flex-start;
        }
        ${StyledPictureBoxMedia} {
          width: 100%;
          max-width: 28.625rem;

          .gatsby-image-wrapper {
            height: auto;
          }
        }
      `}
  }

  @media ${media.tablet} {
    ${({ withInner }) =>
      withInner &&
      css`
        min-height: 35vw;
        ${StyledPictureBoxContent} {
          padding: 9.375rem 0;
        }
      `}
  }

  @media ${customMedia.inner} {
    ${({ pictureBoxType }) =>
      pictureBoxType === PICTURE_BOX_TYPES.left &&
      css`
        ${StyledPictureBoxColMedia} {
          padding-left: calc(
            50% + 1.875rem - ${({ theme }) => theme.sizes.inner} / 2
          );
        }
        ${StyledPictureBoxColContent} {
          padding-right: calc(
            50% + 1.875rem - ${({ theme }) => theme.sizes.inner} / 2
          );
        }
      `}

    ${({ pictureBoxType }) =>
      pictureBoxType === PICTURE_BOX_TYPES.right &&
      css`
        ${StyledPictureBoxColMedia} {
          padding-right: calc(
            50% + 1.875rem - ${({ theme }) => theme.sizes.inner} / 2
          );
        }
        ${StyledPictureBoxColContent} {
          padding-left: calc(
            50% + 1.875rem - ${({ theme }) => theme.sizes.inner} / 2
          );
        }
      `}
  }
`

const StyledPictureBoxHeader = styled.div`
  margin-bottom: 1.25rem;
`

const StyledPictureBoxPlay = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .gatsby-image-wrapper {
    width: 4rem;
  }

  @media ${media.phone} {
    .gatsby-image-wrapper {
      width: 7rem;
    }
  }

  @media ${media.tablet} {
    .gatsby-image-wrapper {
      width: 9rem;
    }
  }
`

export {
  StyledPictureBox,
  StyledPictureBoxInner,
  StyledPictureBoxHeader,
  StyledPictureBoxDesc,
  StyledPictureBoxContent,
  StyledPictureBoxMedia,
  StyledPictureBoxColMedia,
  StyledPictureBoxColContent,
  StyledPictureBoxPlay,
}

import styled, { css } from 'styled-components'

import media from '../../common/MediaQueries'
import Container from '../Container'
import { StyledInternalLink } from '../Buttons/InternalLink/styles'
import { decorLink, decorHoverLink } from '../../common/mixins'

const StyledCalloutAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${media.phone} {
    flex-direction: row;
    justify-content: center;
    width: auto;
  }
`

const StyledCalloutContent = styled.div`
  p + p {
    margin-top: 1.5rem;
  }

  & + ${StyledCalloutAction} {
    margin-top: 2rem;
  }

  a:not([class]) {
    color: ${({ theme }) => theme.colors.yellow};
    ${decorLink};

    &:hover {
      ${decorHoverLink};
    }
  }
`

const StyledCallout = styled(Container)`
  ${StyledInternalLink} {
    &:not(:last-child) {
      margin-bottom: 1.875rem;
    }
  }


  
  ${({ centeredOnMobile }) =>
    centeredOnMobile &&
    css`
      text-align: center;
      ${StyledCalloutAction} {
        align-items: center;
      }
    `}

  @media ${media.phone} {
    text-align: center;

    ${StyledInternalLink} {
      margin-left: 0.9375rem;
      margin-right: 0.9375rem;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    
    ${({ centeredOnMobile }) =>
      centeredOnMobile &&
      css`
        ${StyledCalloutAction} {
          align-items: flex-start;
        }
      `}
  }
`

const StyledCalloutImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`

const StyledCalloutWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  @media ${media.phone} {
    max-width: 85%;
  }
`

const StyledCalloutTitle = styled.div`
  margin-bottom: 1.5rem;

  & + ${StyledCalloutAction} {
    margin-top: 1.5rem;
  }

  @media ${media.phone} {
    margin-left: auto;
    margin-right: auto;

    & + ${StyledCalloutAction} {
      margin-top: 3.125rem;
    }
  }
`

export {
  StyledCallout,
  StyledCalloutWrapper,
  StyledCalloutTitle,
  StyledCalloutContent,
  StyledCalloutAction,
  StyledCalloutImg,
}

import { buildAction } from '../../utils'

const FlexBlockActions = ({ actions, pageType }) => {
  const flexActions = Array.isArray(actions)
    ? actions.map((action, index) => buildAction(action, index, pageType))
    : buildAction(actions)

  return flexActions
}

export default FlexBlockActions

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  StyledModelItem,
  StyledModelItemInner,
  StyledModelImgWrapper,
  StyledModelImgVip,
  StyledModelItemGradient,
  StyledModelItemContent,
  StyledModelItemBio,
  StyledModelItemDetails,
  StyledModelItemAction,
  StyledModelItemActionAlt,
  StyledModelItemContentAlt,
  StyledModelComingSoon,
} from './styles'
import Image from '../Image/Image'
import { Typo3, Body } from '../Typography/Typography'
import { fluidPropType } from '../../constants/proptypes'
import ModelDetails from '../ModelDetails/ModelDetails'
import { URL_PATHS } from '../../constants/urlPaths'
import HideImageContext from '../../contexts/HideImageContext'

const ModelItem = ({
  to,
  image,
  name,
  description,
  details,
  alt,
  comingSoon,
  vipThumbnail,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const { isInvisible } = useContext(HideImageContext)

  return (
    <StyledModelItem
      to={comingSoon ? `${URL_PATHS.CONTACT}` : to}
      alt={alt}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <StyledModelItemInner>
        {comingSoon && (
          <StyledModelComingSoon opacity="1" fontWeight="bold" color="yellow">
            New Top Companion
          </StyledModelComingSoon>
        )}
        <StyledModelImgWrapper comingSoon={comingSoon}>
          {vipThumbnail ? (
            <StyledModelImgVip
              isInvisible={isInvisible}
              src={vipThumbnail}
              alt=""
            />
          ) : (
            <Image fluid={image?.fluid} />
          )}
          <StyledModelItemGradient />
        </StyledModelImgWrapper>

        <StyledModelItemContent>
          {!alt && (
            <StyledModelItemBio>
              {name && <Typo3 as="h4">{name}</Typo3>}
              {description && <Body opacity="0.7">{description}</Body>}
            </StyledModelItemBio>
          )}
          {isVisible && (
            <StyledModelItemDetails>
              <ModelDetails details={details} />
            </StyledModelItemDetails>
          )}
        </StyledModelItemContent>
        {comingSoon ? (
          <StyledModelItemAction>Ask for Info</StyledModelItemAction>
        ) : (
          <StyledModelItemAction>View Profile</StyledModelItemAction>
        )}
      </StyledModelItemInner>
      {alt && (
        <StyledModelItemContentAlt>
          <StyledModelItemBio>
            {name && <Typo3 as="h4">{name}</Typo3>}
            {description && <Body opacity="0.7">{description}</Body>}
          </StyledModelItemBio>
          <StyledModelItemActionAlt>View Profile</StyledModelItemActionAlt>
        </StyledModelItemContentAlt>
      )}
    </StyledModelItem>
  )
}

export default ModelItem

ModelItem.defaultProps = {
  to: '/',
  alt: false,
}

ModelItem.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  image: fluidPropType,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.string,
    }),
  ).isRequired,
  alt: PropTypes.bool,
  comingSoon: PropTypes.bool,
}

export const query = graphql`
  fragment modelDetailsFragment on wp_Model_Acf {
    subtitle
    description
    birthdate
    height
    dressSize
    lingerie
    eyes
    occupation
    languages
  }
`

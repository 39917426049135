import styled from 'styled-components'
import Modal from 'react-modal'

import { transition } from '../../common/mixins'
import media from '../../common/MediaQueries'

const StyledPopupModal = styled(Modal)`
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;

  overflow: hidden;

  &.ReactModal__Content--after-open {
    opacity: 1;
  }
`

const StyledPopupModalInner = styled.div`
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  @media ${media.tablet} {
    max-width: 80%;
  }
`

const StyledPopupModalClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  ${transition}

  svg {
    font-size: 1.25rem;
  }

  @media ${media.tablet} {
    svg {
      font-size: 1.75rem;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`

const StyledPopupModalAction = styled.div`
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;

  @media ${media.tablet} {
    top: 1.875rem;
    right: 1.875rem;
  }
`

export {
  StyledPopupModal,
  StyledPopupModalInner,
  StyledPopupModalAction,
  StyledPopupModalClose,
}

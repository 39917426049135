import React from 'react'
import PropTypes from 'prop-types'

import ModelItem from '../../ModelItem/ModelItem'
import { fluidPropType } from '../../../constants/proptypes'
import {
  StyledModelsListSliderWrapper,
  StyledModelsListSlider,
  StyledModelsListSliderInner,
} from './styles'
import {
  PrevButton,
  NextButton,
} from '../SliderNavigation/ShadowedArrows/ShadowedArrows'
import defaultSliderOptions from '../../../constants/defaultSliderConfig'
import Slider from '../Slider/Slider'

const sliderOptions = {
  ...defaultSliderOptions,
  autoplay: true,
  slidesToShow: 2,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const ModelsListSlider = ({ models, alt }) => (
  <StyledModelsListSliderWrapper {...{ alt }}>
    <StyledModelsListSlider>
      <StyledModelsListSliderInner>
        <Slider
          sliderOptions={sliderOptions}
          slidesArray={models}
          slideComponent={ModelItem}
          {...{ alt }}
        />
      </StyledModelsListSliderInner>
    </StyledModelsListSlider>
  </StyledModelsListSliderWrapper>
)

export default ModelsListSlider

ModelsListSlider.defaultProps = {
  alt: false,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      to: '/',
    }),
  ),
}

ModelsListSlider.propTypes = {
  alt: PropTypes.bool,
  models: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      image: fluidPropType,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          data: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
}

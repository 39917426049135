import React from 'react'
import { useInView } from 'react-intersection-observer'

import OtherModelsSlider from '../../../components/Sliders/OtherModelsSlider/OtherModelsSlider'
import { BG_TYPES } from '../../../constants/bgTypes'
import Container from '../../../components/Container'
import mapOtherModelsToList from '../mapOtherModelsToList'

const FlexOtherModelsSlider = ({ index, wp, block }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const models = wp.models.edges

  const mappedOtherModels = mapOtherModelsToList(models)

  const {
    otherModelsSliderImages,
    otherModelsSliderDetails,
  } = mappedOtherModels

  return (
    <Container
      color={block.bg === BG_TYPES.black ? 'white' : 'black'}
      inView={inView}
    >
      <div className="uiAnimBottom">
        <div ref={ref}>
          {inView ? (
            <OtherModelsSlider
              key={index}
              details={otherModelsSliderDetails}
              images={otherModelsSliderImages}
              bg={block.bg}
              alt={!!block.type}
            />
          ) : null}
        </div>
      </div>
    </Container>
  )
}

export default FlexOtherModelsSlider
